@import "../../../AppColors";
@import "../../../StyleMixins";
@import "../../../Dimensions";

.design-page-body-spinner {
  @include flex(row);
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.design-page-wrapper {
  @include flex(column);
  width: 100vw;
  height: 100vh;
  background-color: $lightColor;
}

.design-page-body {
  @include flex(row);
  width: 100%;
  height: calc(100% - #{$navBarHeight});
}

.design-page-body-left-section {
  @include flex(column);
  width: 70%;
  height: 100%;
  padding: $bodyPadding 0;
}

.design-page-body-left-section-header-row {
  @include flex(row);
  align-items: center;
  justify-content: space-between;
  padding: 0 $bodyPadding;
}

.design-page-body-left-section-header-expand-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
  color: $navColor;
}

.design-page-body-left-section-header-expand-icon:hover {
  color: rgba($navColor, 0.7);
}

.design-page-body-left-section-tabs {
  padding-top: $bodyPadding;
  width: 100%;
  height: 100%;
}

.design-page-body-right-section {
  flex: 1;
  height: 100%;
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";
@import "../../../../Dimensions";

.design-catalogue-wrapper {
  @include flex(column);
  width: 100%;
  max-height: 100%;
  min-height: 100%;
  position: relative;
  padding: 0 $bodyPadding;
}

.design-catalogue-wrapper-overlay {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  z-index: 2000;
}

.design-catalogue-mid-section {
  @include flex(row);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-top: $bodyPadding;
}

.design-catalogue-search-and-result-row {
  width: 500px;
  @include flex(row);
  align-items: center;
  gap: $bodyPadding;

  .design-catalogue-search-result-count {
    width: 100px;
    font-size: calc(5px + 0.5vw);
  }

  .design-catalogue-query-count {
    color: $blackColor;
    font-size: calc(5px + 0.5vw);
  }

  .design-catalogue-search-icon {
    color: $greyColor;
    width: calc(10px + 1vw);
    height: calc(10px + 1vw);
    cursor: pointer;
  }

  .design-catalogue-search-icon:hover {
    color: $blackColor;
  }
}

.design-catalogue-products {
  @include flex(column);
  align-items: center;
  flex: 1;
  width: 100%;
  overflow: scroll;
}

.design-catalogue-products-container {
  @include flex(column);
  align-items: center;
  padding-bottom: 100px;
}

.design-catalogue-products-row {
  @include flex(row);
  flex-wrap: wrap;
  justify-content: center;
  height: 100%;
  width: 100%;
  overflow: scroll;
}

.design-catalogue-no-products {
  @include flex(row);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100%;
  min-height: 100%;

  h5 {
    margin-top: 25px;
  }
}

.design-load-more-button {
  margin-top: 10px;

  &:hover {
    color: $blackColor;
  }
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";
@import "../../../../Dimensions";

.design-canvas-wrapper {
  @include flex(column);
  width: 100%;
  height: 100%;
}

.design-canvas-room-dropdown-label {
  font-size: calc(10px + 0.5vw);
  padding: 0 $bodyPadding;
}

.design-canvas-design-playground {
  padding-top: calc(10px + 0.5vw);
  width: 100%;
  height: 100%;
}

.design-canvas-design-playground-tabs {
  padding: 0 calc(#{$bodyPadding} * 2);
}

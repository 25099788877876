@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";

.filter-by-price-wrapper {
  width: 300px;
}

.filter-by-price-top-section {
  padding: calc(5px + 0.5vw);
}

.filter-by-price-top-section-header {
  @include flex(row);
  align-items: center;
}

.filter-by-price-range-section {
  width: 100%;
  padding: 10px 5px;
}

@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";
@import "../../../../../../Dimensions";

.picker-item-details-wrapper {
  @include flex(column);
  justify-content: center;
  align-items: center;
  padding: 0 calc(5px + 0.5vw) calc(5px + 0.5vw) 0;
}

.picker-item-details-image {
  width: 140px;
  height: 140px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $whiteColor;
  position: relative;
}

.picker-item-details-image-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba($blackColor, 0.8);
  display: none;
  color: $whiteColor;
  padding: 0 10px;
  justify-content: center;
}

.picker-item-details-image:hover .picker-item-details-image-overlay {
  @include flex(column);
}

.picker-item-details-qty {
  @include flex(row);
  justify-content: center;
  align-items: center;
  font-size: calc(5px + 0.5vw);
  padding-top: calc(5px + 0.3vw);
}

.picker-item-details-qty span {
  font-size: calc(5px + 0.5vw);
  margin: 0 calc(3px + 0.3vw);
}

.picker-item-details-qty-icon {
  width: calc(8px + 0.5vw);
  height: calc(8px + 0.5vw);
  cursor: pointer;
}

.picker-item-details-qty-icon:hover {
  color: $whiteColor;
  background-color: $navColor;
  border-radius: 100%;
}

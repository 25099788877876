@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";

.filter-by-item-type-wrapper {
  width: 500px;
}

.filter-by-item-type-top-section {
  @include flex(row);
}

.filter-by-item-type-top-left-section,
.filter-by-item-type-top-right-section {
  padding: calc(10px + 0.5vw);
}

.filter-by-item-type-top-right-section {
  width: 60%;
}

.filter-by-item-type-top-left-section {
  // border-right: 1px solid $greyColor;
}

.filter-by-item-type-top-left-section-items {
  max-height: 300px;
  overflow: scroll;
  padding: calc(5px + 0.5vw);
}

.filter-by-item-type-top-left-section-item {
  padding-bottom: 10px;
}

.filter-by-item-type-top-right-section-selection-wrapper {
  padding-bottom: 40px;
}

.filter-by-item-type-top-right-section-selection-header {
  @include flex(row);
  align-items: center;
}

.filter-by-item-type-top-right-section-selection-label {
  border: 1px solid $greyColor;
  border-radius: 5px;
}

.filter-by-item-type-top-right-section-selection-item {
  width: calc(100px + 1vw);
  text-align: left;
  font-size: calc(6px + 0.5vw);
  color: $blackColor;
}

.filter-by-item-type-top-right-section-selection-inputs {
  @include flex(row);
  width: 100%;
}

.filter-by-item-type-top-right-section-selection-input {
  padding: 10px 5px;
}

@import "../../../../../AppColors";
@import "../../../../../Dimensions";
@import "../../../../../StyleMixins";

.selected-image-download-modal-wrapper {
  position: relative;
  width: 80vw;
  max-width: 1200px;
  height: 80vh;
  border: 1px solid $navColor;
  // border-radius: 10px;

  h4 {
    font-size: 1.3rem;
    text-align: center;
    font-weight: 500;
    background-color: $navColor;
    color: $whiteColor;
    padding: 0.3rem;
    // border-radius: 10px 10px 0 0;
  }
}

.selected-image-download-modal-images {
  @include flex(flex);
  flex-wrap: wrap;
  width: 100%;
}

.selected-image-download-modal-image {
  width: 200px;
  height: 200px;
  object-position: center;
  object-fit: contain;
}

.selected-image-download-modal-footer {
  position: absolute;
  @include flex(flex);
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  bottom: 0;
  border-top: 1px solid $navColor;

  button {
    outline: none;
    border: none;
    background-color: transparent;
    color: $navColor;
    padding: calc(2px + 0.5vw) calc(10px + 0.5vw);
    font-weight: bold;

    &:hover {
      color: $whiteColor;
      background-color: $navColor;
    }
  }
}

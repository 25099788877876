@import "../../../../../../AppColors";

.progress-bar-wrapper {
  background-color: rgba($blackColor, 0.8);
  width: 100%;
  height: 15px;
  border-radius: 10px;
  overflow: hidden;
  // padding: 2px;

  div {
    background-color: $greenColor;
    height: 100%;
  }
}

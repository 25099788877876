.productDetails-modal {
  width: 60vw;
  display: flex;
  flex-direction: column;
}
.productDetails-modal-carousel-container {
  display: flex;
  justify-content: center;
  width: 100%;
  border-bottom: 1px solid grey;
  padding: 10px 0;
}
.carousel .slide {
  background-color: white;
}
.productDetails-modal-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 0;
}

.productDetails-modal-title h6 {
  margin: 0;
  padding: 3px;
}

.productDetails-modal-title h4 {
  margin: 0;
  padding: 3px;
}

.productDetails-modal-description-container {
  width: 100%;
}

.productDetails-modal-description-container p {
  padding: 3px;
  font-size: 12px;
}

.productDetails-modal-details {
  display: flex;
  flex-direction: column;
}

.productDetails-row {
  padding: 10px 5px;
  border-top: 1px solid grey;
  font-size: 12px;
}

.productDetails-row:last-child {
  border-bottom: 1px solid grey;
}

.productDetails-row span {
  font-size: 12px;
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";

.input-box-wrapper {
  width: 100%;
  border: 1px solid $greyColor;
  border-radius: 5px;
  padding: 0 5px;

  input {
    border: none;
    background-color: transparent;
    width: 100%;
  }
}

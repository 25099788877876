@import "../../../../../AppColors";
@import "../../../../../StyleMixins";
@import "../../../../../Dimensions";

.picker-wrapper {
  width: 100%;
}

.picker-wrapper-title-row {
  @include flex(row);
  width: 100%;
  justify-content: space-between;
  align-items: center;
  color: $blackColor;
  padding: calc(10px + 0.5vw) 0;

  h4 {
    font-size: calc(6px + 0.5vw);
  }

  h6 {
    color: rgba($blackColor, 0.8);
    font-size: calc(5px + 0.3vw);
    margin-right: calc(10px + 0.5vw);
  }

  div {
    flex: 1;
    @include flex(row);
    justify-content: flex-end;
    align-items: center;
  }

  .picker-wrapper-title-icon {
    color: rgba($navColor, 0.8);
    cursor: pointer;
  }

  .picker-wrapper-title-icon:hover {
    color: $whiteColor;
    background-color: $navColor;
    border-radius: 100%;
  }
}

.picker-wrapper-draft-container {
  padding-bottom: calc(25px + 0.5vw);
}

.picker-wrapper-draft-item {
}

.picker-wrapper-draft-item-header {
  @include flex(row);
  align-items: center;
  color: rgba($blackColor, 0.9);
  font-size: calc(5px + 0.5vw);
  font-weight: normal;
  margin-top: calc(5px + 0.5vw);
}

.picker-wrapper-draft-item-search-icon {
  font-size: calc(12px + 0.5vw);
  color: rgba($navColor, 0.5);
  margin-left: calc(5px + 0.5vw);
  cursor: pointer;
}

.picker-wrapper-draft-item-search-icon:hover {
  color: $navColor;
}

.picker-wrapper-stats-complete {
  color: $greenColor !important;
}

.picker-wrapper-stats-exceeded {
  color: $redColor !important;
}

.picker-wrapper-draft-item-image {
  width: 150px;
  height: 150px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $whiteColor;
}

.picker-wrapper-draft-item-details {
  @include flex(row);
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
}

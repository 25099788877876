@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";

.filter-by-vendors-wrapper {
  width: 600px;
}

.filter-by-vendors-top-section {
  @include flex(row);
}

.filter-by-vendors-top-left-section {
  padding: calc(10px + 0.5vw);
  border-right: 1px solid $greyColor;
}

.filter-by-vendors-top-mid-section,
.filter-by-vendors-top-right-section {
  padding: calc(10px + 0.5vw);
}

.filter-by-vendors-top-mid-section {
  border-right: 1px solid $greyColor;
}

.filter-by-vendors-top-mid-section-vendor-regions,
.filter-by-vendors-top-right-section-vendors {
  max-height: 300px;
  overflow: scroll;
  padding: calc(5px + 0.5vw);
}

.filter-by-vendors-top-mid-section-vendor-region,
.filter-by-vendors-top-right-section-vendor {
  padding-bottom: 10px;
}

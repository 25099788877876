@import "../../../../AppColors";
@import "../../../../Dimensions";
@import "../../../../StyleMixins";

.success-modal-wrapper {
  @include flex(column);
  justify-content: center;
  align-items: center;
  width: 250px;
}

.success-modal-image {
  background-color: $lightColor;
  @include flex(row);
  justify-content: center;
  align-items: center;
  border: 10px;
  padding: 50px 0;
  margin: 10px 0;
}

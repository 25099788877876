@import "../../../../AppColors";
@import "../../../../StyleMixins";

.checkbox-wrapper {
  @include flex(row);
  align-items: center;
  width: 100%;
}

.checkbox {
  @include flex(row);
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background-color: $navColor;
  border: 1px solid $navColor;
  width: 15px;
  height: 15px;
  //   padding: 2px;
}

.checkbox-icon {
  color: $whiteColor;
}

.checkbox-label {
  margin: 0;
  font-size: 12px;
  color: $navColor;
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";
@import "../../../../Dimensions";

.draft-tabs-wrapper {
  @include flex(row);
}

.draft-tabs-tab {
  @include flex(row);
  justify-content: center;
  align-items: center;
  font-size: calc(5px + 0.5vw);
  background-color: rgba($greyColor, 0.4);
  padding: 0 calc(10px + 0.5vw);
  height: calc(20px + 0.5vw);
  width: fit-content;
  color: $blackColor;
  border-radius: calc(3px + 0.3vw) calc(3px + 0.3vw) 0 0;
  margin-right: calc(3px + 0.3vw);
  cursor: pointer;
}

.draft-tabs-tab-add-icon {
  height: calc(10px + 0.5vw);
  width: calc(10px + 0.5vw);
}

.draft-tabs-tab:hover {
  background-color: $navColor;
  color: white;
}

.draft-tabs-tab-active {
  background-color: $navColor;
  color: white;
  font-weight: bold;
}

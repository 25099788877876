@import "../../../../../../Dimensions";
@import "../../../../../../StyleMixins";
@import "../../../../../../AppColors";

.menuBar-icon-wrapper {
  height: $playgroundMenuBarHeight;
  width: $playgroundMenuBarHeight;
  @include flex(row);
  justify-content: center;
  align-items: center;
  color: $greyColor;
  cursor: pointer;
}

.menuBar-icon-wrapper:hover {
  height: $playgroundMenuBarHeight;
  width: $playgroundMenuBarHeight;
  @include flex(row);
  justify-content: center;
  align-items: center;
  background-color: $navColor;
  color: $whiteColor;
}

$primaryColor: #ff4e24;
$secondaryColor: #fec425;
$accentColor: #0070da;
$whiteColor: #ffffff;
$lightColor: #f5f5f5;
$blackColor: #333333;
$greyColor: #bdbdbd;
$redColor: #eb5757;
$lightRedColor: #fbd5dc;
$greenColor: #5dba7c;
$orangeColor: #f2994a;
$creamColor: #f7efe7;
$navColor: #003941;

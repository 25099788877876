.canvas-display-wrapper {
  width: 100%;
  position: relative;

  .canvas-display-title {
    position: absolute;
    left: 10px;
    top: 10px;
  }
}

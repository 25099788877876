@import "../../../../../AppColors";
@import "../../../../../Dimensions";
@import "../../../../../StyleMixins";

.budget-details-wrapper {
  @include flex(row);
  align-items: center;
  color: rgba($blackColor, 0.8);
  justify-content: space-between;
  width: 100%;

  .budget-details-wrapper-row-item1 {
    width: 45%;
  }

  .budget-details-wrapper-row-item2 {
    width: 18%;
  }

  .budget-details-wrapper-row-item3 {
    width: 35%;
  }

  h3 {
    font-size: calc(5px + 0.3vw);
  }

  // .budget-details-progress-bar {
  //   width: calc(100px + 1vw);
  // }
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";

.range-selector-wrapper {
  width: 100%;
}

.range-selector-sliders {
  @include flex(row);
  align-items: center;
  position: relative;
  width: calc(100%);
  height: 15px;

  .range-selector-slide-background {
    width: 100%;
    height: 5px;
    background-color: $greyColor;
    border-radius: 5px;
  }

  .range-selector-slide1,
  .range-selector-slide2 {
    @include flex(row);
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    height: 15px;
  }

  .range-selector-slide1-range,
  .range-selector-slide2-range {
    width: 100%;
    height: 5px;
    border-radius: 5px;
  }

  .range-selector-slide1-range {
    background-color: $greyColor;
  }

  .range-selector-slide2-range {
    background-color: $navColor;
  }

  .range-selector-slide-handle-wrapper {
    position: absolute;
  }

  .range-selector-slide-handle {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    border: 1.5px solid $navColor;
    background-color: $whiteColor;
  }

  .range-selector-range1-wrapper,
  .range-selector-range2-wrapper {
    width: 100%;
  }

  .range-selector-range1-wrapper {
    padding-top: 50px;
    left: 0;
  }

  .range-selector-range2-wrapper {
    right: 0;
  }
}

.range-selector-range1,
.range-selector-range2 {
  width: 100%;
  position: absolute;
  margin: 0;
  opacity: 0;
  z-index: 4;
}

.range-selector-slide1 {
  z-index: 1;
}

.range-selector-slide2-bring-forward {
  z-index: 2;
}

.range-number-input-row {
  @include flex(row);
  justify-content: space-between;
}

.range-number-input-wrapper {
  padding: 10px 5px;
}

.range-number-input {
  width: 100%;
  border: 1px solid $greyColor;
  border-radius: 5px;
  padding: 0 5px;

  input {
    border: none;
    background-color: transparent;
    width: 100%;
  }
}

@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";

.room-menu-wrapper {
  padding: 10px;
  background-color: $whiteColor;
  color: $blackColor;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
  width: fit-content;
  height: 100%;
  overflow: scroll;

  .room-menu-label {
    @include flex(row);
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 5px;
  }

  h5 {
    margin-right: 5px;
  }

  .room-menu-label-category:hover h5,
  .room-menu-label:hover h5 {
    color: rgba($blackColor, 0.5);
    text-decoration: underline;
  }

  .room-menu-label-category {
    @include flex(row);
    align-items: center;
    width: 100%;
    margin-bottom: 5px;
  }

  h6 {
    margin-left: 15px;
    color: rgba($blackColor, 0.5);
    margin-bottom: 5px;
  }

  h6:hover {
    text-decoration: underline;
  }

  .room-menu-label-category:hover .room-menu-icon,
  .room-menu-label:hover .room-menu-icon {
    color: rgba($blackColor, 0.5);
  }
}

@import "../../../../AppColors";
@import "../../../../Dimensions";
@import "../../../../StyleMixins";

.client-view-wrapper {
  width: 100%;
  @include flex(column);
  align-items: center;
}

.client-view-top-nav {
  @include flex(row);
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.client-view-send-client-button {
  background-color: $navColor;
  color: $whiteColor;
  border-radius: 5px;
}

.client-view-send-client-button:hover {
  background-color: rgba($navColor, 0.8);
}

.client-view-body {
  max-width: 800px;
  p {
    width: 100%;
    text-align: center;
    margin: $bodyPadding 0;
  }
}

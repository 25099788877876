.quote-preview-per-unit-wrapper {
  margin-bottom: calc(50px + 1vw);
  border: 1px solid #f2f2f2;
}

.quote-preview-per-unit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #003941;
  padding: calc(3px + 0.3vw) calc(12px + 0.8vw);
  margin-bottom: calc(12px + 0.8vw);
  width: 100%;
}

.quote-preview-per-unit-header h4 {
  margin: 0;
  color: #ff4b30;
  font-size: calc(6px + 0.5vw);
}

.quote-preview-per-unit-header h5 {
  margin: 0;
  color: #ff4b30;
  font-size: calc(6px + 0.5vw);
}

.quote-preview-per-unit-header span {
  margin: 0;
  color: #ffffff;
  opacity: 0.5;
  font-size: calc(5px + 0.5vw);
}

.quote-preview-per-unit-rooms {
  padding: 0 calc(5px + 0.5vw);
}

.quote-preview-per-unit-header-totals-wrapper {
  width: 30%;
}

.quote-preview-per-unit-header-totals {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.quote-preview-per-unit-header-totals h5 {
  flex: 1;
  margin: 0;
  color: #ffffff;
  opacity: 0.5;
  font-size: calc(5px + 0.5vw);
  text-align: right;
}

.quote-preview-per-unit-header-totals h4 {
  margin: 0;
  color: #ff4b30;
  font-size: calc(6px + 0.5vw);
  text-align: right;
  margin-left: calc(2px + 0.2vw);
}

@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";
@import "../../../../../../Dimensions";

.picker-images-clipboard-modal-wrapper {
  @include flex(column);
  width: 60vw;
}

.picker-images-clipboard-modal-images {
  @include flex(row);
  justify-content: space-between;
  flex-wrap: wrap;

  img {
    margin-bottom: 20px;
  }
}

@import "../../../../../AppColors";
@import "../../../../../StyleMixins";

.filters-wrapper {
  @include flex(row);
  width: 100%;
  height: fit-content;
  color: $greyColor;

  h5 {
    margin-right: 25px;
    font-weight: 500;
    font-size: 12px;
    color: rgba($blackColor, 0.7);
  }

  .filters-filter-icon {
    cursor: pointer;
    width: calc(10px + 1vw);
    height: calc(10px + 1vw);
  }

  .filters-filter-icon:hover {
    color: $blackColor;
  }

  .filters-row {
    @include flex(row);
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    gap: calc(5px + 0.5vw);
    padding-left: calc(10px + 1%);
  }
}

.filters-filter-by-airtable {
  @include flex(row);
  justify-content: flex-end;
  align-items: center;
  // width: 10rem;
  border: 1px solid $greyColor;
  border-radius: 5px;
  padding: 0 5px;
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";
@import "../../../../Dimensions";

.design-playground-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-color: $whiteColor;
  // box-shadow: 0px 0px 5px rgba($blackColor, 0.05);
  // background-color: aquamarine;

  // design-playground-element-wrapper:first-child {
  //   pointer-events: none;
  // }
  // background-color: aqua;
}

.design-playground-menubar {
  // background-color: $whiteColor;
  height: $playgroundMenuBarHeight;
  width: 100%;
  box-shadow: 0px 10px 15px rgba($blackColor, 0.3);
}

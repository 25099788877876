@import "../../../AppColors";

.button {
  background-color: transparent;
  color: $greyColor;
  border: none;
  padding: calc(2px + 0.5vw) calc(10px + 0.5vw);
  font-weight: bold;
  font-size: calc(8px + 0.5vw);
}

// .button:hover {
//   background-color: $blackColor;
//   color: $whiteColor;
// }

@import "../../../../AppColors";
@import "../../../../StyleMixins";

.custom-dropdown-wrapper {
  @include flex(row);
  align-items: center;
  position: relative;

  .custom-dropdown-label-wrapper {
    @include flex(row);
    width: fit-content;
    justify-content: space-between;
    align-items: center;
    background-color: transparent;
    border: 1px solid $greyColor;
    border-radius: 5px;
    color: $greyColor;
    font-weight: bold;
    padding: calc(1px + 0.3vw) calc(5px + 0.3vw);
    cursor: pointer;

    .custom-dropdown-label {
      font-size: calc(4px + 0.5vw);
      margin-right: 2px;
    }

    .custom-dropdown-icon {
      width: calc(4px + 0.5vw);
      height: calc(4px + 0.5vw);
    }
  }

  .custom-dropdown-label-wrapper-active {
    background-color: $navColor;
    border: 1px solid $navColor;
    color: $whiteColor;
  }

  .custom-dropdown-component-wrapper {
    position: absolute;
    top: 100%;
    min-width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: $whiteColor;
    z-index: 1;
  }
}

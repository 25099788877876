@import "../../../AppColors";
@import "../../../Dimensions";
@import "../../../StyleMixins";

.finalize-page-wrapper {
  width: 100vw;
}

.finalize-page-body {
  padding: $bodyPadding;
  width: 100%;
}

@import "../../../../../../AppColors";
@import "../../../../../../StyleMixins";

.filter-action-buttons-wrapper {
  @include flex(row);
  width: 100%;
  justify-content: center;
  align-items: center;
  border-top: 1px solid $greyColor;
  padding: calc(5px + 0.5vw);
  gap: calc(5px + 0.5vw);

  .filter-action-button {
    border: 1px solid $navColor;
    border-radius: 5px;
    padding: calc(5px + 0.5vw) calc(20px + 0.5vw);
    color: $navColor;
  }

  .filter-action-button:hover {
    border: 1px solid rgba($navColor, 0.6);
    color: rgba($navColor, 0.6);
  }

  .filter-action-button-apply {
    background-color: $navColor;
    color: $whiteColor;
    border: 1px solid $navColor;
  }

  .filter-action-button-apply:hover {
    border: 1px solid rgba($navColor, 0.8);
    background-color: rgba($navColor, 0.8);
    color: $whiteColor;
  }
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";

.selector-wrapper {
  @include flex(row);
  border: 1px solid $greyColor;
  width: 100%;
  border-radius: 5px;

  .selector {
    font-size: calc(8px + 20%);
  }

  .selector:hover {
    color: $navColor;
  }

  .selector-active {
    background-color: $navColor;
    color: $whiteColor;
  }

  .selector-active:hover {
    color: $whiteColor;
  }
}

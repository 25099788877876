@import "../../../../AppColors";
@import "../../../../StyleMixins";

.list-dropdown-wrapper {
  @include flex(row);
  align-items: center;
  position: relative;

  .list-dropdown-component-wrapper {
    position: absolute;
    top: 100%;
    min-width: 100%;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
    background-color: $whiteColor;
    z-index: 100;
  }
}

.list-dropdown-label-wrapper {
  @include flex(row);
  width: fit-content;
  justify-content: space-between;
  align-items: center;
  background-color: transparent;
  border-radius: 5px;
  color: $greyColor;
  font-weight: bold;
  // padding: calc(1px + 0.3vw) calc(5px + 0.3vw);
  font-size: calc(4px + 0.5vw);
  cursor: pointer;

  .list-dropdown-label {
    margin-right: 2px;
  }
}

.list-dropdown-label-wrapper:hover {
  text-decoration: underline;
}

.list-dropdown-label-wrapper-active {
  color: $blackColor;
}

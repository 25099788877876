@import "../../../../../AppColors";
@import "../../../../../StyleMixins";

.playground-menubar-wrapper {
  @include flex(row);
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

.playground-menubar-menus {
  @include flex(row);
  align-items: center;
}

.quote-preview-per-room-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-bottom: calc(10px + 2vw);
  border: 1px solid #f5f6fa;
}

.quote-preview-per-room-header {
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: calc(1px + 0.2vw) calc(4px + 0.2vw);
  background-color: #f5f6fa;
  border-bottom: 1px solid #f2f2f2;
}

.quote-preview-per-room-name h5 {
  margin: 1px calc(2px + 0.5vw);
  font-size: calc(5px + 0.5vw);
  color: #252b48;
  opacity: 0.8;
}

.quote-preview-per-room-name p {
  font-size: calc(3px + 0.3vw);
  color: #252b48;
  opacity: 0.4;
  margin: 0 calc(2px + 0.5vw);
}

.quote-preview-per-room-name {
  width: 40%;
}

.quote-preview-per-room-details {
  display: flex;
  width: 60%;
  justify-content: space-between;
  align-items: center;
}

.quote-preview-per-room-item-name {
  width: 60%;
  margin: 0;
  font-size: calc(5px + 0.3vw);
  color: #252b48;
  opacity: 0.2;
}

.quote-preview-per-room-quantity {
  width: 20%;
  margin: 0;
  font-size: calc(5px + 0.3vw);
  color: #252b48;
  opacity: 0.2;
}

.quote-preview-per-room-amount {
  width: 20%;
  margin: 0;
  font-size: calc(5px + 0.3vw);
  color: #252b48;
  opacity: 0.2;
  text-align: center;
}

.quote-preview-per-room-items {
  width: 100%;
}

.quote-preview-per-room-details h5 {
  /* width: 33%; */
}



@media only screen and (max-width: 700px) {

  .quote-preview-per-room-item-name {
    width: 50%;
  }

  .quote-preview-per-room-quantity {
    width: 10%;
  }

  .quote-preview-per-room-amount {
    width: 30%;
  }


}
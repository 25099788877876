@import "../../../../AppColors";
@import "../../../../Dimensions";
@import "../../../../StyleMixins";

.overlay-button-wrapper {
  @include flex(row);
  align-items: center;
  padding-top: 10px;
  cursor: pointer;

  h4 {
    font-size: 10px;
    margin-left: 10px;
  }
}

.overlay-button-wrapper:hover {
  color: rgba($whiteColor, 0.7);
}

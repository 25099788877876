@import "../../../../../AppColors";
@import "../../../../../StyleMixins";

.design-playground-element-wrapper {
  position: absolute;
  width: 20%;
}

.design-playground-element-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  cursor: move;
  @include flex(row);
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.design-playground-element-drag-area {
  width: 100%;
  height: 100%;
}

.design-playground-element-overlay:hover {
  border: 1px solid rgba($navColor, 0.5);
  .design-playground-element-top-handle,
  .design-playground-element-right-handle,
  .design-playground-element-bottom-handle,
  .design-playground-element-left-handle,
  .design-playground-element-top-left-corner,
  .design-playground-element-top-right-corner,
  .design-playground-element-bottom-right-corner,
  .design-playground-element-bottom-left-corner,
  .design-playground-element-top-right-corner-rotate {
    display: inherit;
  }
}

.design-playground-element-top-handle,
.design-playground-element-right-handle,
.design-playground-element-bottom-handle,
.design-playground-element-left-handle,
.design-playground-element-top-left-corner,
.design-playground-element-top-right-corner,
.design-playground-element-bottom-right-corner,
.design-playground-element-bottom-left-corner {
  position: absolute;
  display: none;
  z-index: 500;
}

.design-playground-element-top-handle,
.design-playground-element-bottom-handle {
  left: 2%;
  right: 2%;
  height: 25px;
  cursor: ns-resize;
}

.design-playground-element-right-handle,
.design-playground-element-left-handle {
  top: 2%;
  bottom: 2%;
  width: 25px;
  cursor: ew-resize;
}

.design-playground-element-top-handle {
  bottom: calc(-5px + 100%);
}

.design-playground-element-right-handle {
  left: calc(-5px + 100%);
}

.design-playground-element-bottom-handle {
  top: calc(-5px + 100%);
}

.design-playground-element-left-handle {
  right: calc(-5px + 100%);
}

.design-playground-element-top-left-corner,
.design-playground-element-top-right-corner,
.design-playground-element-bottom-right-corner,
.design-playground-element-bottom-left-corner {
  min-height: 25px;
  min-width: 25px;
}

.design-playground-element-top-left-corner,
.design-playground-element-bottom-right-corner {
  cursor: nwse-resize;
}

.design-playground-element-top-right-corner,
.design-playground-element-bottom-left-corner {
  cursor: nesw-resize;
}

.design-playground-element-top-left-corner {
  right: calc(-10px + 100%);
  bottom: calc(-10px + 100%);
}

.design-playground-element-top-right-corner {
  left: calc(-10px + 100%);
  bottom: calc(-10px + 100%);
}

.design-playground-element-bottom-right-corner {
  left: calc(-10px + 100%);
  top: calc(-10px + 100%);
}

.design-playground-element-bottom-left-corner {
  right: calc(-10px + 100%);
  top: calc(-10px + 100%);
}

.design-playground-element-top-right-corner-rotate {
  min-height: 25px;
  min-width: 25px;
  position: absolute;
  bottom: calc(100% + 10px);
  z-index: 1000;
  font-size: bold;
  display: none;
}

.design-playground-element-top-right-corner-rotate-icon {
  width: 100%;
  height: 100%;
}

@import "../../../../AppColors";
@import "../../../../StyleMixins";
@import "../../../../Dimensions";

.design-item-picker-wrapper {
  width: 100%;
  height: 100%;
  @include flex(column);
}

.design-item-picker-stats-bar {
  width: 100%;
  //   height: 50px;
  background-color: $creamColor;
  padding: calc(5px + 0.5vw) $bodyPadding;
}

.design-item-picker-data-section {
  padding: 0 $bodyPadding;
  flex: 1;
  overflow: scroll;
}

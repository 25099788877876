@import "../../../../../AppColors";
@import "../../../../../StyleMixins";
@import "../../../../../Dimensions";

.sort-wrapper {
  @include flex(row);
}

.sort-item {
  width: calc(150px + 1vw);
  text-align: left;
  font-size: calc(6px + 0.5vw);
  color: $blackColor;
}

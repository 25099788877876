@import "../../../../AppColors";
@import "../../../../Dimensions";
@import "../../../../StyleMixins";

.page-modal-wrapper {
  justify-content: center;
  align-items: center;
  background-color: rgba(37, 43, 72, 0.6);
  position: fixed;
  min-height: 100vh;
  min-width: 100vw;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  z-index: 1;
}

.page-modal-modal {
  position: relative;
  background-color: $whiteColor;
  padding: calc(10px + 1vw);
  border-radius: 10px;
  overflow: scroll;
  z-index: 92;
  max-height: 90vh;
}

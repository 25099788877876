@import "../../../../../AppColors";
@import "../../../../../StyleMixins";
@import "../../../../../Dimensions";

.product-card-wrapper {
  padding: 5px;
}

.product-card {
  @include flex(row);
  position: relative;
  width: 198px;
  height: 200px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: $whiteColor;

  .product-card-item-qty {
    @include flex(row);
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    background-color: $blackColor;
    color: $whiteColor;
    font-size: 10px;
    font-weight: bold;

    .product-card-item-stock-icon {
      width: 15px;
      height: 15px;
    }
  }

  .product-card-overlay {
    @include flex(column);
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba($blackColor, 0.8);
    display: none;
    color: $whiteColor;
    padding: 10px;
    // justify-content: space-between;
    overflow: scroll;

    h5 {
      font-size: 10px;
    }

    h4 {
      font-size: 12px;
      margin-left: 10px;
    }

    .product-card-overlay-action-button {
      @include flex(row);
      align-items: center;
      padding-top: 10px;
      cursor: pointer;
    }

    .product-card-overlay-action-button:hover {
      color: rgba($whiteColor, 0.7);
    }

    .product-card-overlay-action-button-label {
      position: relative;
      // width: 100%;
      z-index: 2000;
      padding-right: 10px;
    }
  }
}

.product-card:hover .product-card-overlay {
  display: inherit;
}

.product-card-overlay-sub-action {
  position: absolute;
  left: 100%;
  top: 0;
  width: max-content;
  height: 200px;
  // width: 200px;
  // background-color: red;
  display: none;
}

.product-card-overlay-action-button-label:hover
  .product-card-overlay-sub-action {
  display: inherit;
}

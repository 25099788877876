.quote-preview-per-item-wrapper {
  display: flex;
  justify-content: space-between;
  padding: calc(5px + 0.5vw) 0;
  width: 100%;
  border-bottom: 1px solid #f5f6fa;

  /* padding: calc(12px + 1vw) calc(20px + 2vw); */
}

.quote-preview-per-item-wrapper h5 {
  margin: 0;
  color: #252b48;
  /* opacity: 0.8; */
  font-weight: 500;
  font-size: calc(8px + 0.4vw);
}

.quote-preview-per-item-images-wrapper {
  width: 40%;
  text-align: center;
}

.quote-preview-per-item-images {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 calc(5px + 0.5vw);
}

.quote-preview-per-item-image {
cursor: pointer;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
}

.quote-preview-per-item-details {
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quote-preview-per-item-qty {
  width: 20%;
}

.quote-preview-per-item-name {
  /* padding-left: 4%; */
  width: 60%;
}

.quote-preview-per-item-amt {
  /* padding-left: 4%; */
  width: 20%;
}

.quote-preview-per-item-name p {
  margin: 0;
  font-size: calc(3px + 0.3vw);
  font-weight: bold;
}

.hero-image {
  width: 50px;
  /* background-image: url("photographer.jpg") */
  /* background-color: #cccccc; */
  /* height: 500px; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}



@media only screen and (max-width: 700px) {

    .quote-preview-per-item-name {
      width: 50%;
    }

    .quote-preview-per-item-qty {
      width: 10%;
    }

  .quote-preview-per-item-amt {
    width: 30%;
  }


}
@import "../../../../AppColors";
@import "../../../../Dimensions";

.horizontal-line-wrapper,
.vertical-line-wrapper {
  background-color: $greyColor;
}

.horizontal-line-wrapper {
  min-width: 100%;
  min-height: 1px;
}

.vertical-line-wrapper {
  min-width: 1px;
  min-height: 100%;
}

@import "../../../../AppColors";
@import "../../../../Dimensions";
@import "../../../../StyleMixins";

.navbar-wrapper {
  @include flex(row);
  justify-content: space-between;
  align-items: center;
  background-color: $navColor;
  width: 100vw;
  height: $navBarHeight;
  padding: 0 calc(20px + 1vw);
}

.navbar-left-section {
  @include flex(row);
  align-items: center;

  .navbar-logo {
    min-width: calc(80px + 1vw);
    margin-right: calc(20px + 1vw);
  }

  .navbar-title {
    @include flex(row);
    align-items: center;
    color: $whiteColor;

    .navbar-title-unit-row {
      @include flex(row);
      align-items: center;

      h3 {
        font-size: calc(6px + 0.5vw);
        font-weight: 500;
      }

      span {
        font-size: calc(6px + 0.5vw);
        font-weight: 500;
        color: $primaryColor;
      }
    }

    h5 {
      font-size: calc(5px + 0.5vw);
      margin-left: calc(10px + 0.5vw);
      font-weight: 400;
    }
  }
}

.navbar-unit-selector-label {
  padding: 0 !important;
  color: $primaryColor !important;
  font-size: calc(6px + 0.5vw) !important;
  font-weight: 500 !important;
}

.navbar-right-section {
  @include flex(row);
  align-items: center;
  color: $whiteColor;

  .navbar-budget-details-wrapper {
    @include flex(column);
    align-items: flex-end;
    justify-content: center;
  }

  .navbar-action-buttons-wrapper {
    @include flex(row);
    align-items: center;

    .navbar-action-icon {
      width: calc(14px + 0.5vw);
      height: calc(14px + 0.5vw);
      margin-left: calc(20px + 1vw);
      cursor: pointer;
    }

    .navbar-action-icon:hover {
      color: $greyColor;
    }

    .navbar-finalize-button {
      border: 1px solid $whiteColor;
      color: $whiteColor;
      border-radius: 10px;
      margin-left: calc(20px + 1vw);
      padding: calc(2px + 0.5vw) calc(10px + 0.5vw);
      font-weight: bold;
    }

    .navbar-finalize-button:hover {
      border: 1px solid $greyColor;
      color: $greyColor;
      background-color: transparent;
    }
  }
}

@media only screen and (max-width: 1000px) {
  .navbar-left-section {
    @include flex(column);
    align-items: flex-start;
  }
}
